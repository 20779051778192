<template>
  <div>
    <section>
      <Breadcrumb
        :title="pageGet.title"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: pageGet.title },
        ]"
      />
    </section>
    <section class="sptb">
      <div class="container">
        <div class="text-justify" v-if="isLoad">
          <h1 class="skeleton w-100 p-4 mb-4"></h1>
          <h4 class="skeleton w-70 leading-normal p-3"></h4>
          <div v-for="item in 2" :key="'loadContent' + item">
            <div class="skeleton w-30 mt-2 p-2"></div>
            <div class="skeleton w-50 mt-2 p-2"></div>
            <div class="skeleton w-20 mt-2 p-2"></div>
            <div class="skeleton w-60 mt-2 p-2"></div>
            <div class="skeleton w-70 mt-2 p-2"></div>
            <div class="skeleton w-10 mt-2 p-2"></div>
            <div class="skeleton w-80 mt-2 p-2"></div>
            <div class="skeleton w-50 mt-2 p-2"></div>
            <div class="skeleton w-70 mt-2 p-2"></div>
            <div class="skeleton w-10 mt-2 p-2"></div>
          </div>
        </div>
        <div class="text-justify" v-if="!isLoad">
          <div v-html="pageGet.content"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
        {
          name: "author",
          content: this.author,
        },
        {
          name: "keywords",
          content: this.keywords,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let pageInfo = {
      id: this.$route.params.id,
      short_name: this.$route.params.shortname,
    };
    this.$store
      .dispatch("pageGet", pageInfo)
      .then((value) => {
        this.title = value[0].title+" - Terapi Vitrini";
        this.keywords = value[0].tags.toString();
        this.author = value[0].fullname;
        this.description = value[0].description;
        this.$store.dispatch("setMetaContent", [
          this.title,
          this.description,
          this.author,
          this.keywords,
          "",
        ]);
        this.pageGet = value[0];
        this.isLoad = false;
      })
      .catch((e) => {
        this.$router.push('/')
      })
  },
  data() {
    return {
      pageGet: [],
      isLoad: true,
      title: "",
      description: "",
      author: "",
      keywords: "",
    };
  },
  watch: {
    "$route.params.id": function () {
      let pageInfo = {
        id: this.$route.params.id,
        short_name: this.$route.params.shortname,
      };
      this.$store.dispatch("pageGet", pageInfo).then((value) => {
        this.title = "Terapi Vitrini -  " + value[0].title;
        this.keywords = value[0].tags.toString();
        this.author = value[0].fullname;
        this.description = value[0].description;
        
        this.pageGet = value[0];
        this.isLoad = false;
        let _this=this;
        $(document).ready(function () {
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          _this.author,
          _this.keywords,
          "",
          content,
        ]);
      });
      }).catch((e) => {
        this.$router.push('/')
      })
    },
  },
  components: { Breadcrumb },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>